import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { GlobalContext } from '../../context/GlobalContext';
import CONFIG from './../../abi/config.json';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { useContext, useEffect } from 'react';

const providerOptions = {
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        20: 'https://api.elastos.io/eth',
      },
      chainID: 1,
    },
  },
};

const styleobj = {
  WebkitTransform:
    'translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)',
  msTransform:
    'translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)',
  transform:
    'translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)',
  opacity: 0,
};

const Main = ({ setError, setErrMsg }) => {
  const { account, addAccount, updateProvider } = useContext(GlobalContext);

  const connectWallet = async () => {
    try {
      const web3modal = new Web3Modal({
        providerOptions,
      });
      const instance = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      updateProvider(provider);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      addAccount({ id: address });
      const network = await provider.getNetwork();
      if (network.chainId !== CONFIG.NETWORK_ID) {
        setError(true);
        setErrMsg(
          `Contract is not deployed on current network. please choose ${CONFIG.NETWORK}`,
        );
      } else {
        setError(false);
        setErrMsg('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        // addAccount({ id: accounts[0] })
        connectWallet();
      });
      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });
    }
  }, [account]);

  return (
    <section>
      <div>
        <div className="section-19 wf-section">
          <div className="div-block-227">
            <div className="div-block-228 earnpagess">
              <div className="earnpage ant-row-ant-row-bottom-mb-5">
                <div className="div-block-91 psjcomi">
                  <div className="div-block-237">
                    <div className="html-embed-13 sa21 w-embed">
                      <div className="ant-col">
                        <span
                          role="img"
                          aria-label="info-circle"
                          className="anticon anticon-info-circle c-icon"
                        >
                          <svg
                            viewbox="64 64 896 896"
                            focusable="false"
                            data-icon="info-circle"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                            <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <h1 className="heading-39 dark-mode-txt staking-mana dark-heading notice">
                      Notice
                    </h1>
                  </div>
                  <h2 className="heading-39 asdajs-231231 small-txts-12 paragrapheds-1 no-pads">
                    Your staked Bunny Punks might not appear immediately on this
                    page due to delay in Subgraph node. However, the boosted
                    utility will be available immediately.
                  </h2>
                </div>
                <div className="div-block-238">
                  <div
                    id="w-node-_09a31d6c-a0cd-ae9f-91fa-f72add3a6e3e-04c92854"
                    className="div-block-239"
                  >
                    <img
                      src="images/61d9abb68b986eae7852c312_untitled-removebg-preview.png"
                      srcset="images/61d9abb68b986eae7852c312_untitled-removebg-preview-p-500.png 500w, images/61d9abb68b986eae7852c312_untitled-removebg-preview.png 500w"
                      sizes="120px"
                      alt=""
                      className="image-114"
                    ></img>
                    <div className="text-block-12 stakenfts">Stake NFTs</div>
                    <p className="paragraph-19 no-filter">NEW</p>
                  </div>
                </div>
                <div className="div-block-233">
                  <div className="div-block-135 dark-div">
                    <div className="sadaskdasda0">
                      <div className="div-block-138 div-hhigher-spacer">
                        <div
                          id="w-node-ce70e715-d014-7d25-e567-5c098753b7e3-04c92854"
                          className="div-block-148 margin-sad-2"
                        >
                          <div className="div-block-144 no-asd2">
                            <div className="div-block-137">
                              <div className="div-block-104">
                                <h1 className="heading-39 dark-mode-txt staking-mana dark-heading">
                                  Bunny Staking
                                </h1>
                                <h2 className="heading-39 asdajs-231231 small-txts-12 paragrapheds-1 no-pads">
                                  Stake your Bunny Punk for $BUNNY rewards! Read
                                  more{' '}
                                  <a
                                    href="https://docs.alpacafinance.org/alpies/boosted-leverage?_gl=1*11gdrvn*_ga*NDQ0MjAxODI4LjE2NjY3MzEyMjU.*_ga_7N9TKNTDN5*MTY2NzUwNjMyNy4xNS4xLjE2Njc1MDYzNjUuMC4wLjA."
                                    target="_blank"
                                  >
                                    here.
                                  </a>
                                </h2>
                              </div>
                            </div>
                            <div className="div-block-159">
                              <div
                                id="w-node-de3a6af5-1c8e-3242-2e7e-780e90203d57-04c92854"
                                className="div-block-145 bg-cgre small-dark-div"
                              >
                                <div className="div-block-146">
                                  <div className="cus-sdasdas ddsadablock">
                                    <h3 className="heading-39 apr-txt asdasdasdasdas _0sidk">
                                      1.000
                                    </h3>
                                    <h2 className="heading-39 asdajs-231231 small-txts-12 totled-asd2 text-small-dark">
                                      TOTAL NFTS
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="w-node-de3a6af5-1c8e-3242-2e7e-780e90203d5e-04c92854"
                                className="div-block-145 bg-cgre small-dark-div"
                              >
                                <div className="div-block-146">
                                  <div className="cus-sdasdas ddsadablock">
                                    <h3 className="heading-39 apr-txt asdasdasdasdas _0sidk">
                                      726
                                    </h3>
                                    <h2 className="heading-39 asdajs-231231 small-txts-12 totled-asd2 text-small-dark">
                                      STAKED NFTS
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="w-node-de3a6af5-1c8e-3242-2e7e-780e90203d65-04c92854"
                                className="div-block-145 bg-cgre small-dark-div"
                              >
                                <div className="div-block-146">
                                  <div className="cus-sdasdas ddsadablock">
                                    <h3 className="heading-39 apr-txt asdasdasdasdas _0sidk">
                                      75.000+
                                    </h3>
                                    <h2 className="heading-39 asdajs-231231 small-txts-12 totled-asd2 text-small-dark">
                                      AVAILABLE
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="div-block-240">
                              <div
                                data-current="Tab 1"
                                data-easing="ease"
                                data-duration-in="300"
                                data-duration-out="100"
                                className="w-tabs"
                              >
                                <div className="tabs-menu-2 w-tab-menu">
                                  <a
                                    data-w-tab="Tab 1"
                                    className="tab-link-tab-1 w-inline-block w-tab-link w--current"
                                  >
                                    <div className="text-block-16">All</div>
                                  </a>
                                  <a
                                    data-w-tab="Tab 2"
                                    className="tab-link-tab-2 w-inline-block w-tab-link"
                                  >
                                    <div className="text-block-17">Staked</div>
                                  </a>
                                </div>
                                <div className="w-tab-content">
                                  <div
                                    data-w-tab="Tab 1"
                                    className="tab-pane-tab-1 w-tab-pane w--tab-active"
                                  >
                                    <div></div>
                                  </div>
                                  <div
                                    data-w-tab="Tab 2"
                                    className="tab-pane-tab-2 w-tab-pane"
                                  >
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="div-block-197">
                              <h1 className="heading-39 dark-mode-txt staking-mana dark-heading sas-s1">
                                Choose staking period in days
                              </h1>
                              <div className="div-block-242">
                                <div className="day1ws">
                                  <a
                                    href="https://v2.bunnypunk.io/"
                                    className="link-block-13 w-inline-block"
                                  >
                                    <p className="paragraph-18">Stake</p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-block-233">
                  <div className="div-block-135 dark-div">
                    <div className="sadaskdasda0 feautred">
                      <div className="div-block-137 feature-d12">
                        <div>
                          <div className="div-block-91">
                            <img
                              src="https://uploads-ssl.webflow.com/61d9a8a4e852ac3d2851ff2e/61fc3e4324a0be5b2e5a9c32_bunnypunk_token%404x.png"
                              alt=""
                              className="image-110"
                            ></img>
                            <h1 className="heading-39 dark-mode-txt dark-heading asd2131">
                              ‍
                              <strong>
                                Want your project featured on Bunny Punk?
                              </strong>
                            </h1>
                          </div>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="c-back-button cursor-pointer relative-btn faq-border bg-dark-tt btnpappla w-inline-block"
                          >
                            <div className="text-block-15 logo-txt faq _21938sahdasjdhas applynow-txt">
                              Apply now
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-block-233">
                  <div className="div-block-205">
                    <h1 className="heading-39 dark-mode-txt staking-mana centeredsa dark-heading">
                      Buy Bunny Punk on secondary market
                    </h1>
                    <div className="div-block-235">
                      <a
                        href="https://ela.city/marketplace/collections/0xe27934fb3683872e35b8d9e57c30978e1260c614"
                        target="_blank"
                        className="c-back-button cursor-pointer relative-btn sda2312sda elacity-btn small-dark-div _2ls w-inline-block"
                      >
                        <img
                          src="images/elacity-1.png"
                          alt=""
                          className="image-112 elacity"
                        ></img>
                        <div className="text-block-15 logo-txt btn-bns btnsn-sd213">
                          ElaCity
                        </div>
                      </a>
                      <a
                        href="https://pasarprotocol.io/collections/detail/10xE27934fB3683872e35b8d9E57c30978e1260c614"
                        target="_blank"
                        className="c-back-button cursor-pointer relative-btn sda2312sda pasarproasd-btn small-dark-div w-inline-block"
                      >
                        <img
                          src="images/pasar-1.svg"
                          alt=""
                          className="image-112 pasar"
                        ></img>
                        <div className="text-block-15 logo-txt btn-bns btnsn-sd213">
                          Pasar
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="app-spacebar"></div>
              </div>
            </div>
          </div>
          <div className="div-block-202 dark">
            <div className="div-block-203">
              <div className="html-embed-11 text-small-dark w-embed">
                <span
                  role="img"
                  aria-label="info-circle"
                  className="anticon anticon-info-circle text-xl"
                >
                  <svg
                    viewbox="64 64 896 896"
                    focusable="false"
                    data-icon="info-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                    <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                  </svg>
                </span>
              </div>
              <h2 className="heading-39 asdajs-231231 small-txts-12 text-small-dark">
                Bunny Punk is a leveraged yield farming product, and using
                leveraged products involves certain risks. Please read
                <a href="#" target="_blank">
                  {' '}
                  here
                </a>{' '}
                to understand these risks. As a user of our protocol, you are in
                agreement that you are aware of these risks, and that all
                liability resides with you. So please don’t invest your life
                savings, or risk assets you can’t afford to lose. Try to be as
                careful with your funds as we are with our code.
              </h2>
              <div className="div-block-204">
                <h2 className="heading-39 asdajs-231231 small-txts-12 inlines-sad">
                  V.2.1.118
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
