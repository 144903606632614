import { useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import Homepage from './Home';
import StakePage from './Stake';

import AlertBox from '../../components_old/AlertBox';
import { useContext } from 'react';

function Main() {
  const { account } = useContext(GlobalContext);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
  });

  useEffect(() => {
    console.log(account);
  }, [account]);

  return (
    <>
      {account ? (
        <StakePage setError={setError} setErrMsg={setErrMsg} />
      ) : (
        <Homepage setErrMsg={setErrMsg} setError={setError} />
      )}

      {error && <AlertBox msg={errMsg} />}
    </>
  );
}
//
export default Main;
