import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import Stakepools from './pages/Stakepools';
import NFTSTAKING from './pages/NFTSTAKING';
import Mintpools from './pages/Mintpools';
import Dashboard from './pages/Dashboard';
import Howto from './pages/Howto';
import MintPassIndex from './pages/MintPass';
import MrsBunnyIndex from './pages/MrsBunnyMinting';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/bp-staking" element={<Main />} />
        <Route exact path="/mint-pools" element={<Mintpools />} />
        <Route exact path="/Stake-pools" element={<Stakepools />} />
        <Route exact path="/nftstaking" element={<NFTSTAKING />} />
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/mint-mrs-bunny" element={<MrsBunnyIndex />} />
        <Route exact path="/how-to" element={<Howto />} />
        <Route exact path="/mintpass" element={<MintPassIndex />} />
      </Routes>
    </BrowserRouter>
  );
}
//
export default App;
