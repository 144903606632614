import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { GlobalContext } from '../../context/GlobalContext';
import CONFIG from './../../abi/config.json';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { useContext, useEffect } from 'react';
import MrsBunny from '../../components/MrsBunny';

const providerOptions = {
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        20: 'https://api.elastos.io/eth',
      },
      chainID: 1,
    },
  },
};

const styleobj = {
  WebkitTransform:
    'translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)',
  msTransform:
    'translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)',
  transform:
    'translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)',
  opacity: 0,
};

const Main = ({ setError, setErrMsg }) => {
  const { account, addAccount, updateProvider } = useContext(GlobalContext);

  const connectWallet = async () => {
    try {
      const web3modal = new Web3Modal({
        providerOptions,
      });
      const instance = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      updateProvider(provider);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      addAccount({ id: address });
      const network = await provider.getNetwork();
      if (network.chainId !== CONFIG.NETWORK_ID) {
        setError(true);
        setErrMsg(
          `Contract is not deployed on current network. please choose ${CONFIG.NETWORK}`,
        );
      } else {
        setError(false);
        setErrMsg('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        // addAccount({ id: accounts[0] })
        connectWallet();
      });
      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });
    }
  }, [account]);

  return (
    <section>
      <MrsBunny />
    </section>
  );
};

export default Main;
